import { Ref, ref, unref, watch } from "vue";
import { uniq } from "lodash";
import { DateTime } from "luxon";
import { DecoratedAsset, Form, FormConfig, MaybeRef, SubmitSelection, FormValues, FieldDescriptor } from "@/types";
import { buildAssetFormConfig, copyPropertyToForm } from "@/config/asset-form";
import { FormHandler, FormHandlerParams } from "./use-form";
import updateDeviceSettings from "@/gql/update-device-settings";
import { getPropertyConfig } from "@/config/asset";

export interface AssetFormHandlerParams extends FormHandlerParams {
  asset: MaybeRef<DecoratedAsset>;
  properties: FieldDescriptor[];
}

export class AssetFormHandler extends FormHandler {
  asset: MaybeRef<DecoratedAsset>;
  properties: FieldDescriptor[];
  dependencyProperties: FieldDescriptor[];
  mutationTimestamp = ref<DateTime | null>(null);

  constructor(form: Ref<Form>, params: AssetFormHandlerParams) {
    super(form, { descriptors: params.properties });
    this.asset = params.asset;
    this.properties = params.properties;
    this.dependencyProperties = this.getDependencies();
  }

  getFormConfig(): Partial<FormConfig> {
    return buildAssetFormConfig(unref(this.asset));
  }

  populateForm(force = false): void {
    const allDescriptors = [...this.properties, ...this.dependencyProperties];
    for (const property of allDescriptors) {
      copyPropertyToForm(unref(this.asset), this.form.value, property, this.mutationTimestamp.value, force);
    }
  }

  afterFirstPopulate(): void {
    super.afterFirstPopulate();
    watch(this.asset, () => this.populateForm(), { deep: true });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async submit(values: FormValues, submitSelection: SubmitSelection): Promise<boolean> {
    let success = false;

    await updateDeviceSettings(unref(this.asset).assetUuid, values)
      .then(({ stamp }) => {
        this.mutationTimestamp.value = DateTime.fromISO(stamp, { zone: "utc", setZone: true });
        this.populateForm();
        success = true;
      })
      .catch(() => {
        success = false;
      });

    return success;
  }

  getDependencies(): FieldDescriptor[] {
    const dependencies = this.properties.flatMap(
      descriptor => getPropertyConfig(unref(this.asset).config, descriptor).dependsOnFields
    );
    return uniq(dependencies);
  }
}
