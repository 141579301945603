import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";
import { GqlMutationResult } from "@/types";

export default function (assetUuid: string, settings: Record<string, any>): Promise<GqlMutationResult> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($assetUuid: ID!, $settings: JSON!) {
          updateDeviceSettings(assetUuid: $assetUuid, settings: $settings) {
            statusCode
            message
            stamp
          }
        }
      `,
      variables: {
        assetUuid,
        settings
      }
    })
    .then(response => {
      const result: GqlMutationResult | undefined = response.data.updateDeviceSettings;
      if (!result) throw Error("updateDeviceSettings mutation did not return a result");

      const { statusCode } = result;
      if (statusCode < 200 || statusCode >= 300)
        throw Error(`updateDeviceSettings failed with statusCode ${statusCode}`);

      return result;
    });
}
