import { mapValues, max, omit, toPairs, assign } from "lodash";
import { DateTime } from "luxon";
import {
  DecoratedAsset,
  DecoratedProperty,
  Field,
  FieldConfig,
  FieldDescriptor,
  FieldUpdateStatus,
  Form,
  FormConfig,
  PropertyConfig,
  PropertyState
} from "@/types";
import { getProperty, getPropertyConfig } from "./asset";
import { getExistingFieldObject, setField, readFieldConfig, expandFieldConfig } from "@/config/form";
import { expandDescriptorToIndexes } from "@/utils/indexed-field";
import { parseTimestamp } from "@/utils/date";
import i18n from "@/plugins/i18n";

const PROPERTY_STATE_TO_UPDATE_STATUS: Record<PropertyState, FieldUpdateStatus> = {
  DOWNLINKED: "pending",
  DOWNLINK_CONFIRMED: "pending",
  UPLINK_MATCHED: "success",
  CONFIRM_TIMEOUT: "failure",
  UPLINK_TIMEOUT: "failure",
  UPLINK_MISMATCH: "failure",
  REFRESHING: "pending",
  ERROR: "failure"
};

export function buildAssetFormConfig(asset: DecoratedAsset): FormConfig {
  const { i18nNamespace, properties, fields } = asset.config;

  const propertyFieldConfigs: Record<string, FieldConfig> = {};
  toPairs(properties).forEach(([name, config]) => {
    const fieldConfigs = buildFieldConfigs(name, config);
    assign(propertyFieldConfigs, fieldConfigs);
  });

  const fieldConfigs = mapValues(fields, config => ({
    ...config
  }));

  return {
    i18nNamespace,
    fields: { ...propertyFieldConfigs, ...fieldConfigs }
  };
}

function buildFieldConfigs(name: string, propertyConfig: PropertyConfig): Record<string, FieldConfig> {
  const fieldConfig = convertPropertyConfigToFieldConfig(propertyConfig, propertyConfig.fieldConfig);
  return expandFieldConfig(name, fieldConfig);
}

function convertPropertyConfigToFieldConfig(
  propertyConfig: PropertyConfig,
  fieldConfig: Partial<FieldConfig>
): FieldConfig {
  const strippedConfig = omit(propertyConfig, ["displayType"]);
  return readFieldConfig({
    ...strippedConfig,
    ...fieldConfig,
    managedState: true
  });
}

export function copyPropertyToForm(
  asset: DecoratedAsset,
  form: Form,
  descriptor: FieldDescriptor,
  formTimestamp: DateTime | null = null,
  force = false
): void {
  const propertyConfig = getPropertyConfig(asset.config, descriptor);
  const descriptors = expandDescriptorToIndexes(asset.properties, descriptor, propertyConfig.dimensions);

  for (const expansion of descriptors) {
    const property = getProperty(asset, expansion);
    copySinglePropertyToForm(property, form, formTimestamp, force);
  }
}

function copySinglePropertyToForm(
  property: DecoratedProperty,
  form: Form,
  formTimestamp: DateTime | null = null,
  force = false
): void {
  if (force || shouldCopyProperty(property, form, property, formTimestamp)) {
    const status = fieldUpdateStatus(property);
    const field: Partial<Field> = {
      value: property.value,
      timestamp: property.timestamp,
      updateInfo: {
        status,
        message: fieldUpdateMessage(property, status)
      }
    };
    setField(form.config, form, property, field);
  }
}

function shouldCopyProperty(
  property: DecoratedProperty,
  form: Form,
  descriptor: FieldDescriptor,
  formTimestamp: DateTime | null
): boolean {
  const destField = getExistingFieldObject(form, descriptor);
  if (!destField) return true;

  const sourceTimestamp = parseTimestamp(property.timestamp);
  if (sourceTimestamp === null) return false;

  const destTimestamp = parseTimestamp(destField.timestamp);
  if (destTimestamp === null) return true;

  const currentTimestamps = [formTimestamp, destTimestamp].filter(v => v !== null) as DateTime[];
  const currentTimestamp = max(currentTimestamps) as DateTime;
  return sourceTimestamp > currentTimestamp;
}

function fieldUpdateStatus(property: DecoratedProperty): FieldUpdateStatus {
  const { state: propertyState, pending } = property;
  if (pending) return "pending";
  if (!propertyState) return "normal";
  return PROPERTY_STATE_TO_UPDATE_STATUS[propertyState];
}

function fieldUpdateMessage(property: DecoratedProperty, status: FieldUpdateStatus): string | undefined {
  if (status === "normal") return undefined;

  const propertyState = property.state ?? "UNKNOWN";
  const translationKey = `state_management.status.${propertyState}`;
  if (!i18n.te(translationKey)) {
    return i18n.t("state_management.status.UNRECOGNIZED", { status: propertyState }).toString();
  }

  return i18n.t(translationKey).toString();
}
